angular.module('org-admin')

  .directive('paymentPlanCard', function() {

    return {
      scope: {},
      bindToController: {
        sale: '<',
        loadPayments: '&',
        payments: '<',
      },
      templateUrl: '/static/org/payment-plans/payment-plan-card.html',
      controllerAs: 'ctrl',
      controller: function($scope, dialog, i18ng) {

        var ctrl = this

        ctrl.getSortedPayments = function() {
          return _.sortByOrder(ctrl.sale.payments, ['is_paid', 'due_date'], ['desc', 'asc'])
        }

        ctrl.editPaymentPlan = function() {
          dialog.confirm({
            directive: 'payment-plan-edit',
            scope: $scope,
            attrs: {
              sale: ctrl.sale,
              persistedPayments: ctrl.payments
            }
          }).then(function() {
            ctrl.loadPayments()
          })
        }

        ctrl.hideEditPaymentPlan = function() {
          return _.every(ctrl.sale.payments, function(payment) {
            return payment.is_paid || payment.status == 'canceled'
          })
        }

        ctrl.getPaymentPlanDescription = function() {
          return (ctrl.sale.payment_term ? i18ng.t('PAYMENT_PLAN_CARD.description') : null)
        }

      }
    }
  })
