angular.module('org-admin')
  .directive('paymentPlanEdit', function() {
    return {
      scope: {},
      templateUrl: '/static/org/payment-plans/payment-plan-edit.html',
      controllerAs: 'ctrl',
      bindToController: {
        sale: '<',
        persistedPayments: '<',
        cancel: '=',
        confirm: '='
      },

      controller: function(Alerts, PaymentPlan, $scope) {
        var ctrl = this
        ctrl.$onInit = function() {
          ctrl.payments = angular.copy(ctrl.persistedPayments) // js-data relations retrieve these through a getter, so explicitly get it and copy it now in order to manipulate them prior to saving
          ctrl.amountSum = ctrl.sale.amount_outstanding
          ctrl.editableInstallments = _.sortBy(getEditableInstallments(), 'due_date')
          ctrl.notEditableInstallments = _.sortBy(getNotEditableInstallments(), 'due_date')
          ctrl.editableTotal = sumAmounts(getAmounts(ctrl.editableInstallments))
          ctrl.offset = getOffset()
          ctrl.remainder = ctrl.offset
        }

        ctrl.getRemainder = function(amountSum) {
          ctrl.remainder = _.round(parseFloat(ctrl.editableTotal) - amountSum, 2) + ctrl.offset
        }

        ctrl.resetDataAndClose = function() {
          return ctrl.cancel()
        }

        ctrl.submit = function() {
          return PaymentPlan.update(String(ctrl.sale.payment_plan_id), { payments: ctrl.payments, notes: ctrl.notes })
            .then(
              function(success) {
                Alerts.success('PAYMENT_PLAN_EDIT.success_edit')
                $scope.$emit('invoice:update')
                $scope.$emit('order:update')
                return ctrl.confirm()
              },
              function(error) {
                var responseError = error.data.error || error.statusText
                ctrl.serverErrors = responseError.messages || [responseError]
              }
            )
        }

        // Private functions
        function getAmounts(payments) {
          return _.pluck(payments, 'amount')
        }

        function sumAmounts(amounts) {
          return _.reduce(amounts, function(sum, num) {
            var floatSum = parseFloat(sum) || 0
            var floatNum = parseFloat(num) || 0
            return floatSum + floatNum
          })
        }

        function isLockedPaymentStatus(payment) {
          return payment.status === 'canceled' || payment.status === 'disputed' || payment.status === 'chargeback'
        }

        function getEditableInstallments() {
          return _.filter(ctrl.payments, function(payment) {
            if (isLockedPaymentStatus(payment)) return false
            return !payment.is_paid && !payment.upfront_payment
          })
        }

        function getNotEditableInstallments() {
          return _.filter(ctrl.payments, function(payment) {
            if (isLockedPaymentStatus(payment)) return true
            return payment.is_paid || payment.upfront_payment
          })
        }

        function getUnpaidInstallments() {
          return _.filter(ctrl.payments, function(payment) {
            return !payment.is_paid
          })
        }

        function getOffset() {
          var unpaidTotal = sumAmounts(getAmounts(getUnpaidInstallments()))
          return _.round(ctrl.amountSum - parseFloat(unpaidTotal), 2)
        }
      }
    }
  })
