angular.module('launch-darkly-flags', [])

  .service('launchDarklyFlags', function(_, $q, $rootScope, $route, $routeParams, LaunchDarklyService, renderContext, showError) {

    var launchDarklyFlags = {}
    var currentOrgId
    var deferred

    function update() {
      if (renderContext.layout === 'error') return

      deferred = $q.defer()
      launchDarklyFlags.promise = deferred.promise

      getFeatures()
        .then(parse)
        .catch(handleError)
        .finally(done)

    }

    function getFeatures() {
      currentOrgId = $routeParams.currentOrgId || ''
      return LaunchDarklyService.client(currentOrgId)
    }

    function parse(ldClient) {
      clearFeatures()
      launchDarklyFlags.seasonsArchiveAndDelete = ldClient.variation('seasons-archive-and-delete', false)
      launchDarklyFlags.transactionExport = ldClient.variation('fintech-transaction-export', true)
      launchDarklyFlags.creditReasons = ldClient.variation('fintech-credit-reasons', true)
      deferred.resolve(launchDarklyFlags)
    }

    function handleError(error) {
      clearFeatures()
      deferred.reject()
      showError(error)
    }

    function clearFeatures() {
      for (var key in launchDarklyFlags) { delete launchDarklyFlags[key] }
    }

    function done() {
      launchDarklyFlags.loaded = true
    }

    $rootScope.$on('$routeChangeSuccess', update)
    if ($route.current) update()

    return launchDarklyFlags

  })
  .run(function($rootScope, launchDarklyFlags) {
    $rootScope.constructor.prototype.launchDarklyFlags = launchDarklyFlags
  })
